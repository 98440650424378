import '../App.css';

const Footer = () => {
    return (
        <footer>
            <title>Footer</title>
        </footer>
    )
}
//            <h3 style={{ textAlign:"right"}}>© Hans Heje</h3>

export default Footer;
