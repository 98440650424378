import './App.css';

import Header from './components/Header';
import Greeting from './components/Greeting';
import Certifications from './components/Certifications';
import Education from './components/Education';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ScrollAnimation from 'react-animate-on-scroll';
import Publications from './components/Publications';

function App() {
  return (
    <div style={{paddingBottom: "0px", marginBottom: "0px"}}>

      <div className="container" style={styling}>
        <Header />
        <div style={{ marginBottom: "10rem" }}><Greeting /></div>

        <ScrollAnimation animateIn="fadeBlockIn" style={margin} duration="2" animateOnce="true" offset="300">
          <div id="about" ><About /></div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeBlockIn" style={margin} duration="2" animateOnce="true" offset="300">
          <div id="experience"><Experience /></div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeBlockIn" style={margin} duration="2" animateOnce="true" offset="300">
          <div id="education"><Education /></div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeBlockIn" style={margin} duration="2" animateOnce="true" offset="300">
          <div id="publications"><Publications /></div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeBlockIn" style={margin} duration="2" animateOnce="true" offset="300">
          <div id="projects"><Projects /></div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeBlockIn" style={margin} duration="2" animateOnce="true" offset="300">
          <div id="certifications"><Certifications /></div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeBlockIn" style={margin} duration="2" animateOnce="true" offset="300">
          <div id="contact"><Contact /></div>
        </ScrollAnimation>

        <Footer />
      </div>
    </div>
  );
}



const styling = {
  position: 'relative',
  maxWidth: '53rem',
  paddingLeft: "1.5%",
  paddingRight: "1.5%"

}

const margin = {
  marginBottom: '10rem',
}

export default App;
